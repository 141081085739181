import React, { useState } from "react";
import TeamModal from "./TeamModal";

const TEAMS = [
  {
    name: 'Uchechi Akwarandu (MSc)',
    role: "MD & CTO",
    description: `serves as Chief Technology Officer (CTO) for E.D. Solution,
    UK. Specialising in system analyst with the aim to examines the short- and long-term needs of organization and develop strategies to increase revenue and performs cost benefit analysis and return-on-investment analysis. Leading the Organization’s technology strategy, by driving product
    innovation and setting the vision for revolution through digitalised information. I am responsible for
    setting the vision for innovation of Exquisite Digital Solution Services. Bringing the Organizations
    vision to market, to keep protecting our rapidly growing customer base against the most advanced
    attacks.`,
    imageUrl: "/assets/img/teams/uche.jpeg",
    facebook: "",
    twitter: '',
    linkedIn: 'https://www.linkedin.com/in/uchechi-akwarandu-592b831b4',
    instagram: 'https://www.instagram.com/iakwarandu/'
  },
  {
    name: 'Susan A.(MBA)',
    role: "IT Auditor",
    description: `Managing the Organization direction in Enterprise Resource Planning (ERP) systems and 
    digitization utilizing cloud capabilities.`,
    imageUrl: "/assets/img/teams/susan.jpeg",
    facebook: "",
    twitter: '',
    linkedIn: '',
    instagram: 'https://www.instagram.com/nijahqueen_19/'
  },
  {
    name: 'Ikechukwu Israel',
    role: "Lead Engineer & Content Designer",
    description: `He is a technology enthusiast and passionate about open-source technology. 
    He can comfortably develop software application on major platform such as Desktop, Mobile and 
    Web. Recently completed first degree program in Computer Science (B.Sc) and has lead a team of 
    software developer on different project. He is a strategic developer because he structures his 
    application using software architectures at his disposal`,
    imageUrl: "/assets/img/teams/iyke.jpeg",
    facebook: "",
    twitter: 'https://twitter.com/iykeprince48',
    linkedIn: 'https://www.linkedin.com/in/ikechukwu-israel-522554121/',
    instagram: 'https://www.instagram.com/iyke.wiz/'
  },
]

const Team = () => {
  const [show, setShow] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({ imageUrl: "", name: "", role: '', description: '', })
  const handleShowModalInfo = (team) => {
    setSelectedTeam(team)
    setShow(true)
  }
  return (
    <section className="scientist-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src="assets/img/star-icon.png" alt="image" /> Team Members
          </span>
          <h2>
            Our Awesome Team<span className="overlay"></span>
          </h2>
          <p>

          </p>
        </div>

        <div className="row">
          {TEAMS.map((team, index) => <div key={index} className="col-lg-4 col-sm-6 col-md-6" >
            <div
              className="single-scientist-item-box wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <div className="image">
                <img onClick={() => handleShowModalInfo(team)} src={team.imageUrl} alt="image" style={{ height: '300px', cursor: 'pointer' }} />

                <ul className="social">
                  <li>
                    <a href={team.facebook} className="d-block" target="_blank">
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={team.twitter} className="d-block" target="_blank">
                      <i className="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={team.instagram} className="d-block" target="_blank">
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={team.linkedIn} className="d-block" target="_blank">
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>{team.name}</h3>
                <span>{team.role}</span>
              </div>
            </div>
          </div>)}

          {/* <div className="col-lg-4 col-sm-6 col-md-6">
            <div
              className="single-scientist-item-box wow fadeInUp"
              data-wow-delay="100ms"
              data-wow-duration="1500ms"
            >
              <div className="image">
                <img src="/assets/img/teams/susan.jpeg" alt="image" style={{ height: '300px' }} />

                <ul className="social">
                  <li>
                    <a href="#" className="d-block" target="_blank">
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-block" target="_blank">
                      <i className="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/nijahqueen_19/" className="d-block" target="_blank">
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-block" target="_blank">
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Susan A.(MBA)</h3>
                <span>IT Auditor</span>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-4 col-sm-6 col-md-6">
            <div
              className="single-scientist-item-box wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="image">
                <img src="/assets/img/teams/iyke.jpeg" alt="image" style={{ height: '300px' }} />

                <ul className="social">
                  <li>
                    <a href="#" className="d-block" target="_blank">
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/iykeprince48" className="d-block" target="_blank">
                      <i className="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/iyke.wiz/" className="d-block" target="_blank">
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/ikechukwu-israel-522554121/" className="d-block" target="_blank">
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Ikechukwu Israel</h3>
                <span>Lead Engineer &amp; Content Designer</span>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-3 col-sm-6 col-md-6">
            <div
              className="single-scientist-item-box wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="image">
                <img src="assets/img/team/img4.jpg" alt="image" />

                <ul className="social">
                  <li>
                    <a href="#" className="d-block" target="_blank">
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-block" target="_blank">
                      <i className="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-block" target="_blank">
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="d-block" target="_blank">
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="content">
                <h3>Marie Curie</h3>
                <span>Support</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <TeamModal show={show} setShow={setShow} imageUrl={selectedTeam.imageUrl} name={selectedTeam.name} role={selectedTeam.role} description={selectedTeam.description} />
    </section>
  );
};

export default Team;
